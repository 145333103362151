
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';
import Tippy, { tippy } from '@tippy.js/react';
import axios from '../../axios-api';
import { toast } from 'react-toastify';
import ReactToPrint from "react-to-print";
import 'tippy.js/dist/tippy.css';
import '../footer/footer.css';
import Aux1 from '../../hoc/Aux1/Aux1';
import './PopUpPrint.css'
let Userimg = require('../../styles/img/user.png');

class ComponentToPrint1 extends Component {

  render() {
    return (
      <div>
        {this.props.data.roomList != undefined ?
        localStorage.getItem("receipttype") =='a'?
        <div>
          <div className="printSpacing"></div>
        <div className="receiptA4">
             <table className="table w-100 ">
               <thead className="text-center">
               <tr><td >{this.props.data.mazaarName}</td></tr>  
               </thead>
 
               <tbody>
                 <tr>
                   <td className="p-0"><table className="detailData w-100">
                   <tr><td ><u>Booking ID:</u></td><td>{this.props.data.bookingID}</td></tr>
                 <tr><td><u>Check-In Date:</u></td><td>{this.props.data.checkIn}</td></tr>
                 <tr><td><u>Days:</u></td><td>{this.props.data.days}</td></tr>
                 <tr><td><u>Expected Check-Out:</u></td><td>{this.props.data.checkOut}</td></tr>
              
                     </table></td>
                 </tr>
                 <tr>
                   <td>
                     <table className="itsDetailTherm w-100">
                       <tr>
                         <td><u>ITS:</u></td>
                         <td className="itsNumRecp">{this.props.data.itsID}</td>
                       </tr>
                       <tr>
                         <td><u>Name:</u></td>
                         <td>{this.props.data.itsName}</td>
                       </tr>
                     </table>
                   </td>
                 </tr>
 
                 <tr>
                   <td>
                     <table className="table-bordered text-center w-100">
                     <tr>
                       <td>Male</td>
                       <td>Female</td>
                       <td>Child</td>
                       <td>Total</td></tr>
                       {this.props.data.visitorCountList != undefined ?
                       <tr>
                       <td>{this.props.data.visitorCountList[2].total}</td>
                       <td>{this.props.data.visitorCountList[1].total}</td>
                       <td>{this.props.data.visitorCountList[0].total}</td>
                       <td>{this.props.data.visitorCountList.length > 3?this.props.data.visitorCountList[3].total:0}</td></tr>
                       :
                       <tr>
                         <td>0</td>
                         <td>0</td>
                         <td>0</td>
                         <td>0</td>
                       </tr>
                       }
                     </table>
                   </td>
                 </tr>
 
                 <tr>
                   <td className="p-0">
                     <table className="w-100 roomBuilding">
                       <tr>
                         <td className="text-center"><u>Room(S):</u></td>
                         <td>
                            {this.props.data.roomList.map(items => (
                              <Aux1>
                                {items.roomNumber} - {items.buildingName},&nbsp;
                              </Aux1>
                                  ))}
                           </td>
                       </tr>
                     </table>
                   </td>
                 </tr>
                 </tbody>
               <tfoot>
                 <tr>
                   <td className="text-right">
                   {this.props.data.createdBy}</td> 
                 </tr>
               </tfoot>
              
             </table>
             <p className="text-left">
             {this.props.data.noteOnReceipt}
                        {/* Note: Kindly complete your <strong>Checkout</strong> procedure before <strong>7:30 PM</strong>. */}
             </p>
             
            </div>
            </div>
        :
        <div>
        <div className="printSpacing"></div>
        <div className="thermalReceipt">
             <table className="table w-100 ">
               <thead className="text-center">
               <tr><td >{this.props.data.mazaarName}</td></tr>  
               </thead>
 
               <tbody>
                 <tr>
                   <td className="p-0"><table className="detailData w-100">
                   <tr><td ><u>Booking ID:</u></td><td>{this.props.data.bookingID}</td></tr>
                 <tr><td><u>Check-In Date:</u></td><td>{this.props.data.checkIn}</td></tr>
                 <tr><td><u>Days:</u></td><td>{this.props.data.days}</td></tr>
                 <tr><td><u>Expected Check-Out:</u></td><td>{this.props.data.checkOut}</td></tr>
              
                     </table></td>
                 </tr>
                 <tr>
                   <td>
                     <table className="itsDetailTherm w-100">
                       <tr>
                         <td><u>ITS:</u></td>
                         <td className="itsNumRecp">{this.props.data.itsID}</td>
                       </tr>
                       <tr>
                         <td><u>Name:</u></td>
                         <td>{this.props.data.itsName}</td>
                       </tr>
                     </table>
                   </td>
                 </tr>
 
                 <tr>
                   <td>
                     <table className="table-bordered text-center w-100">
                     <tr>
                       <td>Male</td>
                       <td>Female</td>
                       <td>Child</td>
                       <td>Total</td></tr>
 
                       {this.props.data.visitorCountList != undefined ?
                       <tr>
                       <td>{this.props.data.visitorCountList[2].total}</td>
                       <td>{this.props.data.visitorCountList[1].total}</td>
                       <td>{this.props.data.visitorCountList[0].total}</td>
                       <td>{this.props.data.visitorCountList.length > 3?this.props.data.visitorCountList[3].total:0}</td></tr>
                       :
                       <tr>
                         <td>0</td>
                         <td>0</td>
                         <td>0</td>
                         <td>0</td>
                       </tr>
                       }
                     </table>
                   </td>
                 </tr>
 
                 <tr>
                   <td className="p-0">
                     <table className="w-100 roomBuilding">
                       <tr>
                         <td className="text-center"><u>Room(S):</u></td>
                         <td>
                            {this.props.data.roomList.map(items => (
                              <span>
                                {items.roomNumber} - {items.buildingName}
                                </span>
                                  ))}
                           </td>
                       </tr>
                     </table>
                   </td>
                 </tr>
                 </tbody>
               <tfoot>
                 <tr>
                   <td className="text-right">
                   {this.props.data.createdBy}</td> 
                 </tr>
               </tfoot>
              
             </table>
             <p className="text-left">
             {this.props.data.noteOnReceipt}
             </p>
             
            </div>
        </div>
        :null}
      </div>
      
    );
  }
}

class ComponentToPrint2 extends Component {

  render() {
    return (
      <div>
       {localStorage.getItem("receipttype") =='a'?
       <div>
       <div className="printSpacing"></div>
          <div className="receiptA4">
             <table className="table w-100 ">
               <thead className="text-center">
               <tr><td >{this.props.data.mazaarName}</td></tr> 
               <tr><td className="brdrT">CHECK-OUT SLIP</td></tr> 
               </thead>
 
               <tbody>
                 <tr>
                   <td className="p-0"><table className="detailData w-100">
                   <tr><td ><u>Booking ID:</u></td><td>{this.props.data.bookingID}</td></tr>
                 <tr><td><u>Check-In Date:</u></td><td>{this.props.data.checkIn}</td></tr>
                 <tr><td><u>Days:</u></td><td>{this.props.data.days}</td></tr>
                 <tr className="chkOtTime"><td >Check-Out:</td><td>{this.props.data.checkOut}</td></tr>
              
                     </table></td>
                 </tr>
                 <tr>
                   <td>
                     <table className="itsDetailTherm w-100">
                       <tr>
                         <td><u>ITS:</u></td>
                         <td className="itsNumRecp">{this.props.data.itsID}</td>
                       </tr>
                       <tr>
                         <td><u>Name:</u></td>
                         <td>{this.props.data.itsName}</td>
                       </tr>
                     </table>
                   </td>
                 </tr>
 
                 <tr>
                   <td>
                     <table className="table-bordered text-center w-100">
                     <tr>
                       <td>Male</td>
                       <td>Female</td>
                       <td>Child</td>
                       <td>Total</td></tr>
 
                       
                       {this.props.data.visitorCountList != undefined ?<tr>
                       <td>{this.props.data.visitorCountList[0].total}</td>
                       <td>{this.props.data.visitorCountList[1].total}</td>
                       <td>{this.props.data.visitorCountList[2].total}</td>
                       <td>{this.props.data.visitorCountList[0].visitorTotal}</td></tr>
                       :
                       <tr>
                       <td>0</td>
                       <td>0</td>
                       <td>0</td>
                       <td>0</td>
                        </tr>
                       }
                       
                    
                    
                     </table>
                   </td>
                 </tr>
 
                 <tr>
                   <td className="p-0">
                     <table className="w-100 roomBuilding">
                       <tr>
                         <td><u>Room(S):</u></td>
                         <td>
                          {this.props.data.roomList != undefined ?
                            this.props.data.roomList.map(items => (
                              <Aux1>
                              {items.roomNumber} - {items.buildingName},&nbsp;
                            </Aux1>
                              ))
                          :null}
                          </td>
                       </tr>
                     </table>
                   </td>
                 </tr>
                 </tbody>
               <tfoot>
                 <tr>
                   <td className="text-right">
                   {this.props.data.createdBy}</td> 
                 </tr>
               </tfoot>
             </table>
            </div>
            </div>
            :
        <div>
          <div className="printSpacing"></div>
          <div className="thermalReceipt">
            <table className="table w-100 ">
              <thead className="text-center">
              <tr><td >{this.props.data.mazaarName}</td></tr> 
              <tr><td className="brdrT">CHECK-OUT SLIP</td></tr> 
              </thead>

              <tbody>
                <tr>
                  <td className="p-0"><table className="detailData w-100">
                  <tr><td ><u>Booking ID:</u></td><td>{this.props.data.bookingID}</td></tr>
                <tr><td><u>Check-In Date:</u></td><td>{this.props.data.checkIn}</td></tr>
                <tr><td><u>Days:</u></td><td>{this.props.data.days}</td></tr>
                <tr className="chkOtTime"><td >Check-Out:</td><td>{this.props.data.checkOut}</td></tr>
             
                    </table></td>
                </tr>
                <tr>
                  <td>
                    <table className="itsDetailTherm w-100">
                      <tr>
                        <td><u>ITS:</u></td>
                        <td className="itsNumRecp">{this.props.data.itsID}</td>
                      </tr>
                      <tr>
                        <td><u>Name:</u></td>
                        <td>{this.props.data.itsName}</td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td>
                    <table className="table-bordered text-center w-100">
                    <tr>
                      <td>Male</td>
                      <td>Female</td>
                      <td>Child</td>
                      <td>Total</td></tr>

                      
                      {this.props.data.visitorCountList != undefined ?<tr>
                      <td>{this.props.data.visitorCountList[0].total}</td>
                      <td>{this.props.data.visitorCountList[1].total}</td>
                      <td>{this.props.data.visitorCountList[2].total}</td>
                      <td>{this.props.data.visitorCountList[0].visitorTotal}</td></tr>
                      :
                      <tr>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                       </tr>
                      }
                      
                   
                   
                    </table>
                  </td>
                </tr>

                <tr>
                  <td className="p-0">
                    <table className="w-100 roomBuilding">
                      <tr>
                        <td><u>Room(S):</u></td>
                        <td>
                         {this.props.data.roomList != undefined ?
                           this.props.data.roomList.map(items => (
                             <span>
                                 {items.roomNumber} - {items.buildingName}
                             </span>
                             ))
                         :null}
                         </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                </tbody>
              <tfoot>
                <tr>
                  <td className="text-right">
                  {this.props.data.createdBy}</td> 
                </tr>
              </tfoot>
            </table>
           </div>
           </div>
       }
     </div>
      
    );
  }
}
//const PopUp = this.props => {
class PopUp extends Component {
    state = {
        RedirectPath: "",
        SelectedBookingID:"",
        Tab:"",
        CRoomList:[],
        ZuwarList:[],
        selectenable:false,
        ReceiptData:{}
      };
      
    CheckOutHandler = (event,items) =>{
        this.setState({RedirectPath:"/Checkout",Tab:"CheckOut2",SelectedBookingID:items})
      }

    AddCheckOutRoomHandler2 = (items)=>{
        if(this.state.CRoomList.length>0)
            {
                const CheckOutRoomindex = this.state.CRoomList.findIndex(x=>x.roomID == items.roomID)
                const TempCheckOutRoomlist = this.state.CRoomList;
                for(let item in TempCheckOutRoomlist)
                {
                    if(item == CheckOutRoomindex)
                    {
                        TempCheckOutRoomlist[item].IsSelected= this.state.CRoomList[CheckOutRoomindex].IsSelected == undefined?true:!this.state.CRoomList[CheckOutRoomindex].IsSelected;
                    }
                }
        
                const RoomSelected = [];
                for (let item in TempCheckOutRoomlist) 
                    {
                    if(TempCheckOutRoomlist[item].IsSelected == true)
                    {
                        RoomSelected.push(TempCheckOutRoomlist[item])
                    }
                    }
                
                this.setState({CRoomList:TempCheckOutRoomlist})
            }
            else{
                const CheckOutRoomindex = this.props.bdata.roomList.findIndex(x=>x.roomID == items.roomID)
                const TempCheckOutRoomlist = this.props.bdata.roomList;
                for(let item in TempCheckOutRoomlist)
                {
                    if(item == CheckOutRoomindex)
                    {
                        TempCheckOutRoomlist[item].IsSelected= true;
                    }
                }
        
                const RoomSelected = [];
                for (let item in TempCheckOutRoomlist) 
                    {
                    if(TempCheckOutRoomlist[item].IsSelected == true)
                    {
                        RoomSelected.push(TempCheckOutRoomlist[item])
                    }
                    }
                
                this.setState({CRoomList:TempCheckOutRoomlist})
            }
    } 
    AddCheckOutRoomHandler = (items)=>{
        if(this.state.CRoomList.length == 0)
        {
            //this.setState({CRoomList:this.props.bdata.roomList})
            this.AddCheckOutRoomHandler2(items);
        }
        else
        {
            this.AddCheckOutRoomHandler2(items);
        }
       
        // const updatedCheckOutRoom = updateObject(
        //     this.state.CRoomList[CheckOutRoomindex],
        //     {
        //         IsSelected: !items.IsSelected }
        // );
       
    }
    AddCheckOutZuwarHandler = (items)=>{
        if(this.state.ZuwarList.length == 0)
        {
            const CheckOutZuwarindex = this.props.bdata.visitorList.findIndex(x=>x.itsid === items.itsid)
            const TempCheckOutZuwarlist = this.props.bdata.visitorList;
            for(let item in TempCheckOutZuwarlist)
            {
                if(item == CheckOutZuwarindex)
                {
                    TempCheckOutZuwarlist[item].IsSelected=true
                }
            }

            const ZuwarSelected = [];
            for (let item in TempCheckOutZuwarlist) 
                {
                if(TempCheckOutZuwarlist[item].IsSelected == true)
                {
                    ZuwarSelected.push(TempCheckOutZuwarlist[item])
                }
                }
        
            this.setState({ZuwarList:TempCheckOutZuwarlist})
        }
        else{
        const CheckOutZuwarindex = this.state.ZuwarList.findIndex(x=>x.itsid === items.itsid)
        const TempCheckOutZuwarlist = this.state.ZuwarList;
        for(let item in TempCheckOutZuwarlist)
        {
            if(item == CheckOutZuwarindex)
            {
                TempCheckOutZuwarlist[item].IsSelected=this.state.ZuwarList[CheckOutZuwarindex].IsSelected == undefined?true:!this.state.ZuwarList[CheckOutZuwarindex].IsSelected;
            }
        }

        const ZuwarSelected = [];
        for (let item in TempCheckOutZuwarlist) 
            {
            if(TempCheckOutZuwarlist[item].IsSelected == true)
            {
                ZuwarSelected.push(TempCheckOutZuwarlist[item])
            }
            }
       
        this.setState({ZuwarList:TempCheckOutZuwarlist})
        }
    }
    SelectHandler=()=>{
        this.setState({selectenable:!this.state.selectenable})
    }
    ReceiptHandler = (items,Checkinflag)=>{
        let RoomSelected = [];
        let ZuwarSelected = [];
        // let Checkindate="";
        // let Checkoutdate="";
        for (let item in this.state.CRoomList) 
            {
            if(this.state.CRoomList[item].IsSelected != undefined && this.state.CRoomList[item].IsSelected == true)
            {
                RoomSelected.push(this.state.CRoomList[item])
            }
            }
        for (let item in this.state.ZuwarList) 
            {
            if(this.state.ZuwarList[item].IsSelected != undefined && this.state.ZuwarList[item].IsSelected == true)
            {
                ZuwarSelected.push(this.state.ZuwarList[item])
            }
            }
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
          };
        
          const Param = {
                 bookingID:items,
                 roomList:RoomSelected,
                 visitorList:ZuwarSelected,
                 IsActive:Checkinflag==true?1:0
            };
        
          axios.post( '/CheckOut/GetCheckinCheckoutReceiptData',Param,config)
              .then( res => {
                  if(res.data.result.bookingID != undefined )
                  {
                    
                    this.setState({ReceiptData:res.data.result});
                    if(Checkinflag == true)
                    {
                      document.getElementById('clickButtonPrint1').click();
                    }
                    else
                    {
                      document.getElementById('clickButtonPrint2').click();
                    }
                  //this.AddDefaultRooms();
                  }
                  else{
                    this.setState({ReceiptData:undefined});
                  }
                  
              } )
              .catch( err => {
                  toast.error("Error in accessing data")
                
              } );
    }
    render() {
    if(this.state.RedirectPath !="")
      {
          return <Redirect to={{
            pathname: this.state.RedirectPath,
            state:{RoomPage :this.state.SelectedBookingID,Tab:this.state.Tab}
          }} />;
      }

    return (

      <footer>
        {/* Modal Search*/}
         {/* Receipt Modal */}
        
        {/* Message dialogue */}
        <div className="modal fade messageDialogue" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div className="modal-body text-center">
                <p className="m-0">New message goes here</p>
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">OK</button>
                <button type="button" className="btn btn-primary">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        {/* Message dialogue ends */}
        {/*Booking Modal*/}
        <div className="modal fade reportModal " tabIndex={-1} role="dialog" aria-hidden="true" >
            
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">

                <div className="modal-body">
                {this.props.bdata != undefined ?
                  <div className="container-fluid">
                    <div className="row mb-4">
                      <div className="col-8"><h2 className="m-0">Booking Details - <u><b>{this.props.bdata.bookingID}</b></u> &nbsp;
                      {this.props.bdata.bookingStatus == "Occupied" ?<span className="badge badge-success">{this.props.bdata.bookingStatus}</span>
                        : this.props.bdata.bookingStatus == "Checked-Out" ?<span className="badge badge-pending">{this.props.bdata.bookingStatus}</span>
                        : <span className="badge badge-warning">{this.props.bdata.bookingStatus}</span>
                      }</h2></div>
                      <div className="col-4 text-right"><button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  </button></div>
                    </div>
                    <div className="row">

                      <div className="col-2">
                        <div className="personalInfo">
                          <div className="nameAndIts text-center">
                            <div className="img"><img src={Userimg} alt="Generic placeholder image" /></div>
                              <p className="name ">{this.props.bdata.itsName}</p>
                              <p className="itsNo">{this.props.bdata.itsid}</p>
                          </div>
                          <div className="d-flex justify-content-between ">
                                <div className="p-1">
                                  <h5>Check In</h5>
                                  <p>{this.props.bdata.checkIn}</p>
                                  {/* <p>09:12 am</p> */}
                                </div>
                              
                                <div className="p-1 text-right">
                                  <h5>Check Out</h5>
                                  <p>{this.props.bdata.checkOut}</p>
                                  {/* <p>10:30 am</p> */}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between ">
                                <div className="p-1">
                                  <h5>Phone No.</h5>
                                  <p>{this.props.bdata.contactNumber == ''?'-':this.props.bdata.contactNumber}</p>
                                </div>
                              
                                <div className="p-1 text-right">
                                  <h5>Email</h5>
                                  <p>{this.props.bdata.emailID == ''?'-':this.props.bdata.emailID}</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between ">
                                <div className="p-1">
                                  <h5>Address</h5>
                                  <p>{this.props.bdata.address == ''?'-':this.props.bdata.address}</p>
                                </div>
                              
                                {/* <div className="p-1 text-right">
                                  <h5>Last Visit</h5>
                                  <p>12/03/16</p>
                                </div> */}
                              </div>
                              <div className="d-flex justify-content-between ">
                                <div className="p-1">
                                  <h5>Remarks</h5>
                                  <p>{this.props.bdata.bookingRemarks == ''?'-':this.props.bdata.bookingRemarks}</p>
                                </div>
                              </div>
                        </div>
                        <div className="visibilityHide">
                          <ReactToPrint 
                          trigger={() => <div id="clickButtonPrint1" >Print this out</div>}
                          content={() => this.componentRef1}
                          />
                          </div>
                          <div className="visibilityHide">
                            <ReactToPrint 
                            trigger={() => <div id="clickButtonPrint2" >Print this out</div>}
                            content={() => this.componentRef2}
                            />
                            </div>
                      </div>
                      <div className="col-5 borderLR">
                        <div className="userServiceInfo">
                        <div className="form-row mt-4">
                            <div className="form-group col-md-5">
                            <h4><u>Room Details</u></h4>
                            </div>
                            <div className="form-group col-md-7 text-right">
                            <div className="custom-control-inline ml-4 custom-checkbox ">
                                    {/* <label
                                    className="custom-control-label"
                                    htmlFor="selectmanually"
                                    >Select Manually</label>
                                     <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="selectmanually"
                                    //checked={this.state.selectenable}
                                    onChange={()=>this.SelectHandler()}
                                    /> */}

                                    <Aux1>
                                    <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="selectmanually"
                                    //checked={items.IsSelected}
                                    onChange={()=>this.SelectHandler()}
                                    />
                                    <label
                                    className="custom-control-label"
                                    htmlFor="selectmanually"
                                    >Select Manually</label></Aux1>
                                    
                                    </div>
                            </div>
                           </div> 
                        
                        <table className="table borderless table-fixed roomDetaillist">
                      <thead>
                        <tr>
                          {/* <th></th> */}
                          <th>Room No</th>
                          <th>Check-In</th>
                          <th>Check-Out</th>
                          <th>Hours / Days</th>
                          <th>Status</th>   
                        </tr>
                      </thead>
                      <CustomScroll>
                        <tbody>
                        {this.props.bdata.roomList.map(items => (
                         <tr>
                            {this.state.selectenable == true? 
                                <td>
                                <div className="custom-control custom-checkbox">
                                    <Aux1>
                                    <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={items.roomID}
                                    //checked={items.IsSelected}
                                    onChange={()=>this.AddCheckOutRoomHandler(items)}
                                    />
                                    <label
                                    className="custom-control-label mb-4"
                                    htmlFor={items.roomID}
                                    /></Aux1>
                                {items.roomNumber} ({items.buildingName})</div>
                                </td>
                                :
                                <td>{items.roomNumber} ({items.buildingName})
                                </td>}
                          <td>{items.checkIn}</td>
                          <td>{items.checkOut}</td>
                          <td>{items.hours}</td>
                          <td>{items.roomStatus == "Occupied" ?<span className="badge badge-success">{items.roomStatus}</span>
                                : items.roomStatus == "Building Check-Out Pending" ?<span className="badge badge-warning">{items.roomStatus}</span>
                                : <span className="badge badge-pending">{items.roomStatus}</span>
                            }</td>
                         </tr>
                        ))}
                        
                         </tbody>
                      </CustomScroll>
                    </table>
                    <div className="form-row mt-4">
                            <div className="form-group col-md-5">
                            <h4><u>Donation Details</u></h4>
                            </div>
                            <div className="form-group col-md-7 text-right">
                              Total Amount : <b>{this.props.bdata.donationList.length > 0 ?this.props.bdata.donationList[0].donationTotal:0 }</b>
                            </div>
                           </div> 
                   
                        <table className="table borderless table-fixed donationDetaillist">
                     
                      <CustomScroll>
                        <tbody>
                        {this.props.bdata.donationList.map(items => (
                         <tr>
                          <td><b>{items.accountForName}</b></td>
                          <td><b>{items.amount == 0 ? items.comments:items.amount}</b></td>
                          <td/>
                          <td/>
                         </tr>
                         ))}
                        
                         </tbody>
                      </CustomScroll>
                    </table>
                        
                        </div>
                      </div>
                      <div className="col-5">
                        {/* <div className="otherVisitorDetails"> */}
                        <div className="userServiceInfo">
                          <h4><u>Other Visitor Details</u></h4>
                          <div className="form-row mt-4">
                            <div className="form-group col-md-9">
                              {this.props.bdata.visitorList.length > 0 ?  
                              'Male:'+ this.props.bdata.visitorList[0].male +' , Female:'+this.props.bdata.visitorList[0].female +' ,Child:'+this.props.bdata.visitorList[0].child
                              :'Male:0'+' ,Female:0'+' ,Child:0'}
                            </div>
                            <div className="form-group col-md-3 text-right">
                              Total Visitor : {this.props.bdata.visitorList.length > 0 ?this.props.bdata.visitorList[0].total:0}
                            </div>
                           </div> 
                          <div>
                          <table className="table borderless table-fixed visitorList">
                      <thead>
                        <tr>
                          {/* <th></th> */}
                          <th>ITS Number</th>
                          <th>Visitor Name</th>
                          <th>Age</th>
                          {/* <th>Gender</th> */}
                          {/* <th>Address</th> */}
                          <th>Check-In</th>
                          <th>Check-Out</th>
                          <th>Status</th>
                          
                        </tr>
                      </thead>
                      <CustomScroll>
                        <tbody>
                        {this.props.bdata.visitorList.map(items => (
                         <tr>
                          {this.state.selectenable == true?
                          <td className="">
                          <div className="custom-control custom-checkbox">
                          <Aux1>
                                <input
                                type="checkbox"
                                className="custom-control-input"
                                id={items.itsid}
                                //checked={items.IsSelected}
                                onChange={()=>this.AddCheckOutZuwarHandler(items)}
                                />
                                <label
                                className="custom-control-label"
                                htmlFor={items.itsid}
                            /></Aux1>
                            <img src={Userimg} alt="Generic placeholder image" /><br/> {items.itsid} {items.warning !=""? <Tippy placement="right-start" content={items.warning}
                            ><span style={{cursor:'pointer'}} className="exclamBtn" /></Tippy>:null}</div>
                          </td>:
                           <td className="text-center">
                             <img src={Userimg} alt="Generic placeholder image" /><br/> {items.itsid} {items.warning !=""? <Tippy placement="right-start" content={items.warning}
                             ><span style={{cursor:'pointer'}} className="exclamBtn" /></Tippy>:null}
                           </td>  
                            }
                          <td>{items.itsName}</td>
                          <td>{items.age} ({items.gender})</td>
                          {/* <td>Male</td> */}
                          {/* <td>Pune|kasdkajs  kjashd akashdkas hkasdk askjhaskdjhakshdkj ashk jhd </td> */}
                          <td>{items.checkIn}</td>
                          <td>{items.checkOut}</td>
                          {/* <td>Building CheckOut Pending</td> */}
                          <td>{items.status == "Checked-In" ?<span className="badge badge-success">{items.status}</span>
                                : items.status == "Building Check-Out Pending" ?<span className="badge badge-warning">{items.status}</span>
                                : <span className="badge badge-pending">{items.status}</span>
                            }</td>
                         </tr>
                          ))}
                        
                         </tbody>
                      </CustomScroll>
                    </table>
                          </div>
                        </div>
                      </div>
                      {this.props.bdata.checkOutFlag == 1 ?
                      <div className="col-8 text-right mt-4">
                        <button className="btn mr-2" data-dismiss="modal" aria-label="Close" onClick={()=>this.ReceiptHandler(this.props.bdata.bookingID,true)}>Re-Print Check-In Receipt</button>
                        <button className="btn" data-dismiss="modal" aria-label="Close" onClick={()=>this.ReceiptHandler(this.props.bdata.bookingID,false)}>Re-Print Check-Out Receipt</button>
                        {/* <button className="btn text-right" data-dismiss="modal" aria-label="Close">Re-Print Check-Out Receipt</button> */}
                      </div>:
                      <div className="col-12 text-center mt-4">
                        <button className="btn" data-dismiss="modal" aria-label="Close" onClick={()=>this.ReceiptHandler(this.props.bdata.bookingID,false)}>Re-Print Check-Out Receipt</button>
                      </div>
                       }
                      {this.props.bdata.checkOutFlag == 1 && (this.props.showcheckout == undefined || this.props.showcheckout == true)?
                      <div className="col-4 text-right mt-4">
                        <button className="btn text-right" data-dismiss="modal" aria-label="Close" onClick={(event)=>this.CheckOutHandler(event,this.props.bdata.bookingID)}>Check-Out</button>
                      </div>
                       :null }
                    </div>
                  </div>
                :null}   

                </div>
              </div>
            </div>
            
          </div>

        {/* <div className="modal fade receiptModal" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog  modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Receipt Viewer</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div className="modal-body">
                 <div className="receiptLayout">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" colSpan="4" className="border-0">Mazaar Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">ITS No.</th>
                        <td colSpan="2">30442841</td>
                        <th scope="row" rowSpan="2"><h1>Check In</h1></th>
                        
                      </tr>
                      <tr>
                        <th scope="row">Booking No.</th>
                        <td colSpan="2">1183346</td>
                      </tr>
                      <tr>
                        <th scope="row">Arrival Date</th>
                        <td >03 Feb 2020 02:07 PM</td>
                        <th scope="row">Expected Departure Date</th>
                        <td >04 Feb 2020 02:07 PM</td>
                      </tr>
                      <tr>
                        <th scope="row">Name</th>
                        <td colSpan="3">Yusuf Rampurwala</td>
                      </tr>
                      <tr>
                        <th scope="row">Jamaat</th>
                        <td>Bilaspur</td>
                        <th>Days of Stay</th>
                        <td>1</td>
                      </tr>

                      <tr>
                        <td colSpan="3" className="align-top text-center">
                          <table className="table table-borderless">
                            <tr><th>Building Name</th>
                              <th>Room No.</th>
                              </tr>
                            <tr><th>Hakimi Manzil</th>
                              <th>101</th>
                              </tr>
                          </table>
                        </td>
                        <td >
                          <table className="table table-bordered">
                            <tr><td>Male</td><td>0</td></tr>
                            <tr><td>Female</td><td>4</td></tr>
                            <tr><td>Children</td><td>0</td></tr>
                            <tr><td>Total</td><td>4</td></tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-borderless">
                    <tbody>
                      <tr><td>M. Huzfa bhai Amravatiwala</td>
                        <td className="text-right">03 Feb 2020 02:07 PM</td></tr>
                      <tr><td colSpan="2"><p>Note: Kindly complete your <strong>Checkout</strong> procedure before <strong>7:30 PM</strong>.</p></td></tr>
                    </tbody>
                  </table>
                  <div className="text-right"><button className="btn" >Print</button></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade receiptModal2" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog  modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Receipt Viewer</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div className="modal-body">
                      <div className="receiptLayout">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" colSpan="4">Mazaar Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">ITS No.</th>
                        <td>40469574</td>
                        <th>Booking No.</th>
                        <td>1183351</td>
                      </tr>
                      <tr>
                        <th scope="row">Name</th>
                        <td colSpan="3">Yusuf Rampurwala</td>

                      </tr>
                      <tr>
                        <th scope="row">Arrival</th>
                        <td>03 Feb 2020 5:18 PM</td>
                        <th>No. of Days</th>
                        <td>1</td>
                      </tr>
                      <tr>
                        <th scope="row">Checkout Date</th>
                        <td colSpan="3">04 Feb</td>
                      </tr>
                      <tr>
                        <th scope="row">Checkout Time</th>
                        <td>5:18 PM</td>
                        <th>Total No of Person</th>
                        <td>3</td>
                      </tr>
                      <tr>
                        <th scope="row">Building</th>
                        <td colSpan="1">Hakimi Manzil</td>
                        <td colSpan="1">233</td>
                        
                      </tr>
                      <tr>
                        <td>Male:1</td>
                        <td>Female:1</td>
                        <td>Child:0</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="4"><h1>Check Out</h1></th>
                      </tr>
                      <tr>
                        <td colSpan="2">M Buhanuddin Bhai Raj</td>
                        <td colSpan="2">04 Feb 2020 10:03 AM</td>

                      </tr>
                    </tbody>
                  </table>
                  <div className="text-right"><button className="btn" >Print</button></div>
                </div>
               
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade receiptModal3" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog  modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Receipt Viewer</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div className="modal-body">
                <div className="receiptLayout receiptLayout3">
         
                  <div className="row justify-content-center">
                    <div className="col-6">
                      <table className="table table-borderless text-uppercase">
                        <thead>
                          <tr><th className="text-center">Mazaar Name</th></tr></thead>
                      </table>
                      <table className="table table-borderless text-uppercase">
                        <thead><tr><th>dawat-e-hadiyah</th></tr>
                          <tr><th>Mazaar Address</th></tr>
                          <tr><th>WAQF REGD NO. 00</th></tr></thead>
                      </table>
                      <table className="table table-borderless text-uppercase text-center">
                        <tbody>
                          <tr>
                            <td>Receipt No : <strong className="text-underline">110</strong></td>
                            <td>Date : <strong >4 feb 2020 10:03 AM</strong></td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table table-borderless text-uppercase text-center">
                        <tbody>
                          <tr>
                            <td className="lead">حفظه الله تعالى <strong><u>Mr. Yusuf Rampurwala</u></strong> الاخ النجيب </td>

                          </tr>

                        </tbody>
                      </table>

                      <table className="table table-borderless text-uppercase ">
                        <tbody>
                          <tr>
                            <td>ITS No: 40469574</td>
                          </tr>
                          <tr>
                            <td>Address: Address goes here</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table table-borderless text-uppercase text-center">
                        <tbody>
                          <tr>
                            <td></td>
                            <td className="text-center">بعد السلام الجميل</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td><strong><u> Six Hundread only </u></strong>اب طرف سي روبية<strong><u> 600.00 </u></strong>انكه</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>طريقء وصول تهيا  <strong>   Voluntary contribution   </strong></td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table table-borderless text-uppercase ">
                        <tbody>
                          <tr>
                            <td>عبد سيدنا المنعام ط ع </td>
                                                      </tr>
                          <tr>


                            <td>MR. Yusuf rampurwala</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-right"><button className="btn" >Print</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
         */}
        {/* Receipt Modal Ends */}
        <div style={{ display: "none" }}><ComponentToPrint1 data={this.state.ReceiptData} ref={el => (this.componentRef1 = el)} /></div>
        <div style={{ display: "none" }}><ComponentToPrint2 data={this.state.ReceiptData} ref={el => (this.componentRef2 = el)} /></div>
        
      </footer>

    );
    }
}

export default PopUp;


