import React from 'react';

import classes from './Spinner.css';

const spinner = () => (
    <div className="loader h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
);

export default spinner;
