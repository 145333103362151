import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import PopUp from "../../PopUp/PopUp";
import axios from "../../../axios-api";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import Tippy, { tippy } from "@tippy.js/react";
import Aux1 from "../../../hoc/Aux1/Aux1";
import "../../PopUp/PopUpPrint.css";
import "./TopHeader.css";

let Userimg = require("../../../styles/img/user.png");
const logo = require("../../../styles/img/logo.png");
const bellicon = require("../../../styles/img/bellicon.png");
const userimage = require("../../../styles/img/user.png");
const chatIcon = require("../../../styles/img/chatIcon.png");
const reportIcon = require("../../../styles/img/reportIcon.png");
const tickeIcon = require("../../../styles/img/ticket.png");
const hrIcon = require("../../../styles/img/hr-services2.png");
const searchIcon = require("../../../styles/img/serchIcon.png");
const betaIcon = require("../../../styles/img/beta.png");

class ComponentToPrint3 extends Component {
  render() {
    return (
      //Check-In
      <div>
        {this.props.data.roomList != undefined ? (
          localStorage.getItem("receipttype") == "a" ? (
            <div>
              <div className="printSpacing"></div>
              <div className="receiptA4">
                <table className="table w-100 ">
                  <thead className="text-center">
                    <tr>
                      <td>{this.props.data.mazaarName}</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="p-0">
                        <table className="detailData w-100">
                          <tr>
                            <td>
                              <u>Booking ID:</u>
                            </td>
                            <td>{this.props.data.bookingID}</td>
                          </tr>
                          <tr>
                            <td>
                              <u>Check-In Date:</u>
                            </td>
                            <td>{this.props.data.checkIn}</td>
                          </tr>
                          <tr>
                            <td>
                              <u>Days:</u>
                            </td>
                            <td>{this.props.data.days}</td>
                          </tr>
                          <tr>
                            <td>
                              <u>Expected Check-Out:</u>
                            </td>
                            <td>{this.props.data.checkOut}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table className="itsDetailTherm w-100">
                          <tr>
                            <td>
                              <u>ITS:</u>
                            </td>
                            <td className="itsNumRecp">
                              {this.props.data.itsID}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <u>Name:</u>
                            </td>
                            <td>{this.props.data.itsName}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <table className="table-bordered text-center w-100">
                          <tr>
                            <td>Male</td>
                            <td>Female</td>
                            <td>Child</td>
                            <td>Total</td>
                          </tr>
                          {this.props.data.visitorCountList != undefined ? (
                            <tr>
                              <td>
                                {this.props.data.visitorCountList[2].total}
                              </td>
                              <td>
                                {this.props.data.visitorCountList[1].total}
                              </td>
                              <td>
                                {this.props.data.visitorCountList[0].total}
                              </td>
                              <td>
                                {this.props.data.visitorCountList.length > 3
                                  ? this.props.data.visitorCountList[3].total
                                  : 0}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td className="p-0">
                        <table className="w-100 roomBuilding">
                          <tr>
                            <td className="text-center">
                              <u>Room(S):</u>
                            </td>
                            <td>
                              {this.props.data.roomList.map((items) => (
                                <Aux1>
                                  {items.roomNumber} - {items.buildingName}
                                  ,&nbsp;
                                </Aux1>
                              ))}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-right">
                        {this.props.data.createdBy}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <p className="text-left">{this.props.data.noteOnReceipt}</p>
              </div>
            </div>
          ) : (
            <div>
              <div className="printSpacing"></div>
              <div className="thermalReceipt">
                <table className="table w-100 ">
                  <thead className="text-center">
                    <tr>
                      <td>{this.props.data.mazaarName}</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="p-0">
                        <table className="detailData w-100">
                          <tr>
                            <td>
                              <u>Booking ID:</u>
                            </td>
                            <td>{this.props.data.bookingID}</td>
                          </tr>
                          <tr>
                            <td>
                              <u>Check-In Date:</u>
                            </td>
                            <td>{this.props.data.checkIn}</td>
                          </tr>
                          <tr>
                            <td>
                              <u>Days:</u>
                            </td>
                            <td>{this.props.data.days}</td>
                          </tr>
                          <tr>
                            <td>
                              <u>Expected Check-Out:</u>
                            </td>
                            <td>{this.props.data.checkOut}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table className="itsDetailTherm w-100">
                          <tr>
                            <td>
                              <u>ITS:</u>
                            </td>
                            <td className="itsNumRecp">
                              {this.props.data.itsID}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <u>Name:</u>
                            </td>
                            <td>{this.props.data.itsName}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <table className="table-bordered text-center w-100">
                          <tr>
                            <td>Male</td>
                            <td>Female</td>
                            <td>Child</td>
                            <td>Total</td>
                          </tr>

                          {this.props.data.visitorCountList != undefined ? (
                            <tr>
                              <td>
                                {this.props.data.visitorCountList[2].total}
                              </td>
                              <td>
                                {this.props.data.visitorCountList[1].total}
                              </td>
                              <td>
                                {this.props.data.visitorCountList[0].total}
                              </td>
                              <td>
                                {this.props.data.visitorCountList.length > 3
                                  ? this.props.data.visitorCountList[3].total
                                  : 0}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td className="p-0">
                        <table className="w-100 roomBuilding">
                          <tr>
                            <td className="text-center">
                              <u>Room(S):</u>
                            </td>
                            <td>
                              {this.props.data.roomList.map((items) => (
                                <span>
                                  {items.roomNumber} - {items.buildingName}
                                </span>
                              ))}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-right">
                        {this.props.data.createdBy}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <p className="text-left">{this.props.data.noteOnReceipt}</p>
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }
}

class ComponentToPrint2 extends Component {
  render() {
    return (
      //Check-Out
      <div>
        {localStorage.getItem("receipttype") == "a" ? (
          <div>
            <div className="printSpacing"></div>
            <div className="receiptA4">
              <table className="table w-100 ">
                <thead className="text-center">
                  <tr>
                    <td>{this.props.data.mazaarName}</td>
                  </tr>
                  <tr>
                    <td className="brdrT">CHECK-OUT SLIP</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="p-0">
                      <table className="detailData w-100">
                        <tr>
                          <td>
                            <u>Booking ID:</u>
                          </td>
                          <td>{this.props.data.bookingID}</td>
                        </tr>
                        <tr>
                          <td>
                            <u>Check-In Date:</u>
                          </td>
                          <td>{this.props.data.checkIn}</td>
                        </tr>
                        <tr>
                          <td>
                            <u>Days:</u>
                          </td>
                          <td>{this.props.data.days}</td>
                        </tr>
                        <tr className="chkOtTime">
                          <td>Check-Out:</td>
                          <td>{this.props.data.checkOut}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table className="itsDetailTherm w-100">
                        <tr>
                          <td>
                            <u>ITS:</u>
                          </td>
                          <td className="itsNumRecp">
                            {this.props.data.itsID}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <u>Name:</u>
                          </td>
                          <td>{this.props.data.itsName}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table className="table-bordered text-center w-100">
                        <tr>
                          <td>Male</td>
                          <td>Female</td>
                          <td>Child</td>
                          <td>Total</td>
                        </tr>

                        {this.props.data.visitorCountList != undefined ? (
                          <tr>
                            <td>{this.props.data.visitorCountList[0].total}</td>
                            <td>{this.props.data.visitorCountList[1].total}</td>
                            <td>{this.props.data.visitorCountList[2].total}</td>
                            <td>
                              {this.props.data.visitorCountList[0].visitorTotal}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td className="p-0">
                      <table className="w-100 roomBuilding">
                        <tr>
                          <td>
                            <u>Room(S):</u>
                          </td>
                          <td>
                            {this.props.data.roomList != undefined
                              ? this.props.data.roomList.map((items) => (
                                  <Aux1>
                                    {items.roomNumber} - {items.buildingName}
                                    ,&nbsp;
                                  </Aux1>
                                ))
                              : null}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-right">{this.props.data.createdBy}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <div className="printSpacing"></div>
            <div className="thermalReceipt">
              <table className="table w-100 ">
                <thead className="text-center">
                  <tr>
                    <td>{this.props.data.mazaarName}</td>
                  </tr>
                  <tr>
                    <td className="brdrT">CHECK-OUT SLIP</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="p-0">
                      <table className="detailData w-100">
                        <tr>
                          <td>
                            <u>Booking ID:</u>
                          </td>
                          <td>{this.props.data.bookingID}</td>
                        </tr>
                        <tr>
                          <td>
                            <u>Check-In Date:</u>
                          </td>
                          <td>{this.props.data.checkIn}</td>
                        </tr>
                        <tr>
                          <td>
                            <u>Days:</u>
                          </td>
                          <td>{this.props.data.days}</td>
                        </tr>
                        <tr className="chkOtTime">
                          <td>Check-Out:</td>
                          <td>{this.props.data.checkOut}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table className="itsDetailTherm w-100">
                        <tr>
                          <td>
                            <u>ITS:</u>
                          </td>
                          <td className="itsNumRecp">
                            {this.props.data.itsID}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <u>Name:</u>
                          </td>
                          <td>{this.props.data.itsName}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table className="table-bordered text-center w-100">
                        <tr>
                          <td>Male</td>
                          <td>Female</td>
                          <td>Child</td>
                          <td>Total</td>
                        </tr>

                        {this.props.data.visitorCountList != undefined ? (
                          <tr>
                            <td>{this.props.data.visitorCountList[0].total}</td>
                            <td>{this.props.data.visitorCountList[1].total}</td>
                            <td>{this.props.data.visitorCountList[2].total}</td>
                            <td>
                              {this.props.data.visitorCountList[0].visitorTotal}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td className="p-0">
                      <table className="w-100 roomBuilding">
                        <tr>
                          <td>
                            <u>Room(S):</u>
                          </td>
                          <td>
                            {this.props.data.roomList != undefined
                              ? this.props.data.roomList.map((items) => (
                                  <span>
                                    {items.roomNumber} - {items.buildingName}
                                  </span>
                                ))
                              : null}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-right">{this.props.data.createdBy}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}
      </div>

      // <div>
      //       <div className="receiptToPrintp">
      //         <div className="receiptLayout">
      //           {/* <h2>Receipt for Mazar E Fakhri</h2> */}
      //           <table class="table table-bordered">
      //             <thead>
      //               <tr>
      //                 <th scope="col" colSpan="4">{this.props.data.mazaarName}</th>
      //               </tr>
      //             </thead>
      //             <tbody>
      //               <tr>
      //                 <th scope="row">ITS No.</th>
      //                 <td>{this.props.data.itsID}</td>
      //                 <th>Booking No.</th>
      //                 <td>{this.props.data.bookingID}</td>
      //               </tr>
      //               <tr>
      //                 <th scope="row">Name</th>
      //                 <td colSpan="3">{this.props.data.itsName}</td>

      //               </tr>
      //               <tr>
      //                 <th scope="row">Arrival</th>
      //                 <td>{this.props.data.checkIn}</td>
      //                 <th>No. of Days</th>
      //                 <td>{this.props.data.days}</td>
      //               </tr>
      //               {/* <tr>
      //                 <th scope="row">Checkout Date</th>
      //                 <td colSpan="3">04 Feb</td>
      //               </tr> */}
      //               <tr>
      //                 <th scope="row">Checkout Date</th>
      //                 <td>{this.props.data.checkOut}</td>
      //                 <th>Total No of Person</th>
      //                 <td>{this.props.data.visitorCountList != undefined ?this.props.data.visitorCountList[0].visitorTotal:null}</td>
      //               </tr>
      //               {this.props.data.roomList != undefined ?
      //               this.props.data.roomList.map(items => (
      //                 <tr>
      //                     <th scope="row">Building</th>
      //                     <td colSpan="1">{items.buildingName}</td>
      //                     <td colSpan="1">{items.roomNumber}</td>
      //                 </tr>
      //                 ))
      //                 :null}

      //               <tr>
      //               <tr>
      //               {this.props.data.visitorCountList != undefined ?
      //               this.props.data.visitorCountList.map(items => (

      //                     <td>{items.visitorType}:{items.total}</td>
      //                 ))
      //                 :null}
      //                 </tr>
      //               </tr>
      //               <tr>
      //                 <th scope="row" colSpan="4"><h1>Check Out</h1></th>
      //               </tr>
      //               <tr>
      //                 <td colSpan="2">{this.props.data.createdBy}</td>
      //                 <td colSpan="2">{this.props.data.createdOn}</td>

      //               </tr>
      //             </tbody>
      //           </table>

      //       </div>
      //        </div>
      // </div>
    );
  }
}
class topheader extends Component {
  state = {
    ShowSearch: false,
    Searchkey: "",
    SearchList: [],
    TileList: [],
    RedirectPath: "",
    SelectedBookingID: "",
    Tab: "",
    CRoomList: [],
    ZuwarList: [],
    selectenable: false,
    ReceiptData: {},
    ReceiptType: localStorage.getItem("receipttype"),
    url: "",
  };

  componentDidMount() {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };

    axios
      .get("/Report/GetReportList", config)
      .then((res) => {
        const fetchedList = res.data.result;
        if (fetchedList.length > 0) {
          this.setState({
            TileList: fetchedList,
            ReportID: fetchedList[0].reportID,
          });
        } else {
          //  this.setState({ NoDataAvailableShow: true });
        }
      })
      .catch((err) => {
        //toast.error("Error in accessing data");
        //this.setState({ loading: false });
      });
  }

  ShowSearchHandler = () => {
    this.setState({ ShowSearch: !this.state.ShowSearch });
  };
  SearchDataHandler = (event) => {
    this.setState({ Searchkey: event.target.value });
  };
  SearchList = () => {
    if (this.state.Searchkey.length < 1) {
      toast.error("Atleast 1 Character Required");
    } else {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      };

      const Param = {
        SearchKey: this.state.Searchkey,
        ID: 0,
      };
      axios
        .post("/TravelHistory/GlobalSearch", Param, config)
        .then((res) => {
          if (res.data.result.length > 0) {
            this.setState({ SearchList: res.data.result });
          } else {
            this.setState({ SearchList: [] });
            toast.info("No Record Found");
          }
        })
        .catch((err) => {
          toast.error("Error in accessing data");
          this.setState({ loading: false });
        });
    }
  };

  BPopUpHandler = (bookingID, showcheckout) => {
    //event.preventDefault();
    //this.setState({bpopupdata:bookingID})
    //return (<PopUp bookingID = {bookingID}/>)
    const config = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };

    const Param = {
      SearchKey: bookingID,
    };

    axios
      .post("/Booking/GetBookingDataByIDForPopUp", Param, config)
      .then((res) => {
        if (res.data.result.bookingID != undefined) {
          this.setState({ bdata: res.data.result, showcheckout: showcheckout });
          //this.AddDefaultRooms();
        } else {
          this.setState({ bdata: undefined });
        }
      })
      .catch((err) => {
        toast.error("Error in accessing data");
      });
  };

  ReportHandler = (event, items) => {
    event.preventDefault();
    this.setState({ ReportID: items.reportID });
  };

  ////////////////////////PopUp//////////////////////
  CheckOutHandler = (event, items) => {
    this.setState({
      RedirectPath: "/Checkout",
      Tab: "CheckOut2",
      SelectedBookingID: items,
    });
  };

  AddCheckOutRoomHandler2 = (items) => {
    if (this.state.CRoomList.length > 0) {
      const CheckOutRoomindex = this.state.CRoomList.findIndex(
        (x) => x.roomID == items.roomID
      );
      const TempCheckOutRoomlist = this.state.CRoomList;
      for (let item in TempCheckOutRoomlist) {
        if (item == CheckOutRoomindex) {
          TempCheckOutRoomlist[item].IsSelected =
            this.state.CRoomList[CheckOutRoomindex].IsSelected == undefined
              ? true
              : !this.state.CRoomList[CheckOutRoomindex].IsSelected;
        }
      }

      const RoomSelected = [];
      for (let item in TempCheckOutRoomlist) {
        if (TempCheckOutRoomlist[item].IsSelected == true) {
          RoomSelected.push(TempCheckOutRoomlist[item]);
        }
      }

      this.setState({ CRoomList: TempCheckOutRoomlist });
    } else {
      const CheckOutRoomindex = this.state.bdata.roomList.findIndex(
        (x) => x.roomID == items.roomID
      );
      const TempCheckOutRoomlist = this.state.bdata.roomList;
      for (let item in TempCheckOutRoomlist) {
        if (item == CheckOutRoomindex) {
          TempCheckOutRoomlist[item].IsSelected = true;
        }
      }

      const RoomSelected = [];
      for (let item in TempCheckOutRoomlist) {
        if (TempCheckOutRoomlist[item].IsSelected == true) {
          RoomSelected.push(TempCheckOutRoomlist[item]);
        }
      }

      this.setState({ CRoomList: TempCheckOutRoomlist });
    }
  };
  AddCheckOutRoomHandler = (items) => {
    if (this.state.CRoomList.length == 0) {
      //this.setState({CRoomList:this.state.bdata.roomList})
      this.AddCheckOutRoomHandler2(items);
    } else {
      this.AddCheckOutRoomHandler2(items);
    }

    // const updatedCheckOutRoom = updateObject(
    //     this.state.CRoomList[CheckOutRoomindex],
    //     {
    //         IsSelected: !items.IsSelected }
    // );
  };
  AddCheckOutZuwarHandler = (items) => {
    if (this.state.ZuwarList.length == 0) {
      const CheckOutZuwarindex = this.state.bdata.visitorList.findIndex(
        (x) => x.itsid === items.itsid
      );
      const TempCheckOutZuwarlist = this.state.bdata.visitorList;
      for (let item in TempCheckOutZuwarlist) {
        if (item == CheckOutZuwarindex) {
          TempCheckOutZuwarlist[item].IsSelected = true;
        }
      }

      const ZuwarSelected = [];
      for (let item in TempCheckOutZuwarlist) {
        if (TempCheckOutZuwarlist[item].IsSelected == true) {
          ZuwarSelected.push(TempCheckOutZuwarlist[item]);
        }
      }

      this.setState({ ZuwarList: TempCheckOutZuwarlist });
    } else {
      const CheckOutZuwarindex = this.state.ZuwarList.findIndex(
        (x) => x.itsid === items.itsid
      );
      const TempCheckOutZuwarlist = this.state.ZuwarList;
      for (let item in TempCheckOutZuwarlist) {
        if (item == CheckOutZuwarindex) {
          TempCheckOutZuwarlist[item].IsSelected =
            this.state.ZuwarList[CheckOutZuwarindex].IsSelected == undefined
              ? true
              : !this.state.ZuwarList[CheckOutZuwarindex].IsSelected;
        }
      }

      const ZuwarSelected = [];
      for (let item in TempCheckOutZuwarlist) {
        if (TempCheckOutZuwarlist[item].IsSelected == true) {
          ZuwarSelected.push(TempCheckOutZuwarlist[item]);
        }
      }

      this.setState({ ZuwarList: TempCheckOutZuwarlist });
    }
  };
  SelectHandler = () => {
    this.setState({ selectenable: !this.state.selectenable });
  };
  ReceiptHandler = (items, Checkinflag) => {
    let RoomSelected = [];
    let ZuwarSelected = [];
    // let Checkindate="";
    // let Checkoutdate="";
    for (let item in this.state.CRoomList) {
      if (
        this.state.CRoomList[item].IsSelected != undefined &&
        this.state.CRoomList[item].IsSelected == true
      ) {
        RoomSelected.push(this.state.CRoomList[item]);
      }
    }
    for (let item in this.state.ZuwarList) {
      if (
        this.state.ZuwarList[item].IsSelected != undefined &&
        this.state.ZuwarList[item].IsSelected == true
      ) {
        ZuwarSelected.push(this.state.ZuwarList[item]);
      }
    }
    const config = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };

    const Param = {
      bookingID: items,
      roomList: RoomSelected,
      visitorList: ZuwarSelected,
      IsActive: Checkinflag == true ? 1 : 0,
    };

    axios
      .post("/CheckOut/GetCheckinCheckoutReceiptData", Param, config)
      .then((res) => {
        if (res.data.result.bookingID != undefined) {
          this.setState({ ReceiptData: res.data.result });
          if (Checkinflag == true) {
            document.getElementById("clickButtonPrint1").click();
            //window.location.reload(false);
          } else {
            document.getElementById("clickButtonPrint2").click();
            //window.location.reload(false);
          }
          //this.AddDefaultRooms();
        } else {
          this.setState({ ReceiptData: undefined });
        }
      })
      .catch((err) => {
        toast.error("Error in accessing data");
      });
  };
  ////////////////////End PopUp/////////////////////

  ChangeReceiptTypeHandler = () => {
    localStorage.setItem(
      "receipttype",
      localStorage.getItem("receipttype") == "a" ? "t" : "a"
    );
    this.setState({ ReceiptType: this.state.ReceiptType == "a" ? "t" : "a" });
    window.location.reload(false);
  };

  TicketHandler = () => {
    debugger;

    const config = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };

    axios
      .get("/OMS/ValidateOMSUser", config)
      .then((res) => {
        var tempurl =
          "https://oms.mazaraat.org/#/?id=" +
          localStorage.userId +
          "&token=" +
          res.data.result;
        this.setState({ url: tempurl });
        document.getElementById("link").click();
      })
      .catch((err) => {
        toast.error("Error in accessing data");
      });
  };

  BetaCICOHandler = () => {
    debugger;

    const config = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };

    axios
      .get("/OMS/ValidateOMSUser", config)
      .then((res) => {
        var tempurl =
          "http://betacico.mazaraat.org/#/?id=" +
          localStorage.userId +
          "&token2=" +
          res.data.result;
        this.setState({ url: tempurl });
        document.getElementById("link").click();
      })
      .catch((err) => {
        toast.error("Error in accessing data");
      });
  };

  render() {
    if (this.state.RedirectPath != "") {
      return (
        <Redirect
          to={{
            pathname: this.state.RedirectPath,
            state: {
              RoomPage: this.state.SelectedBookingID,
              Tab: this.state.Tab,
            },
          }}
        />
      );
    }
    return (
      <>
        <header>
          {/* <nav className={[classes.topbar,classes.navbar ,classes.navbar-expand-lg ,classes.navbar-dark ,classes.fixed-top].join(' ')}> */}
          <nav className="topbar navbar navbar-expand-lg navbar-dark fixed-top ">
            <NavLink className="navbar-brand" to={localStorage.getItem("link")}>
              <img src={logo} className="img-fluid" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto align-items-center">
                {/* <marquee
                  direction="left"
                  height="24px"
                  style={{color: "#fff"}}>
                  CICO BETA CICO BETA CICO BETA CICO BETA CICO BETA CICO BETA CICO BETA CICO BETA CICO BETA CICO BETA
                </marquee> */}

                {/* <li className="nav-item">

            <a className="nav-link" data-toggle="modal" data-target=".messageDialogue">
              New message
                  </a>
          </li> */}
                {/* <li className="nav-item">
            <a style={{cursor:'pointer'}} className="text-white" data-toggle="modal" data-target=".searchModal">.</a>
          </li> */}

                {/* {localStorage.getItem("khidmatguzar") == "true" &&
                this.state.TileList.length > 0 ? (
                  <li className="nav-item ">
                    <form className="form-inline ">
                      <div className="headerSearch d-flex align-items-center">
                        
                        <input data-toggle="modal" data-target=".searchModal" className="form-control search" type="search" placeholder="Search" aria-label="Search" />
                        <input type="search" className="form-control" placeholder="Search Booking ID, ITS, Name" onChange={event=>this.SearchcheckoutHandler(event)}/>
                        
                        {this.state.ShowSearch == true ? (
                          <div>
                            <input
                              title={this.state.Searchkey}
                              maxlength="100"
                              type="search"
                              className="form-control mr-1"
                              placeholder="Search"
                              onChange={(event) =>
                                this.SearchDataHandler(event)
                              }
                            />
                            <div
                              className="btn btn-bordered btnSmall mr-4"
                              data-toggle="modal"
                              data-target=".searchModal"
                              onClick={() => this.SearchList()}
                            >
                              {" "}
                              GO
                            </div>
                          </div>
                        ) : null}
                        <div
                          title={
                            this.state.ShowSearch == true
                              ? "Hide Search"
                              : "Show Search"
                          }
                          className="searchIcon"
                          data-toggle="modal"
                          data-target=".searchModal"
                           onClick={() => this.ShowSearchHandler()}
                        />
                      </div>
                    </form>
                  </li>
                ) : null} */}
                {/* <li className="nav-item reportLink"><div className='searchIcon'></div></li> */}
                {localStorage.getItem("khidmatguzar") == "true" &&
                this.state.TileList.length > 0 ? (
                  <li className="nav-item chatLink">
                    <div
                      title="search"
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target=".searchModal"
                    >
                      <img src={searchIcon} />
                    </div>
                  </li>
                ) : null}
                {localStorage.getItem("khidmatguzar") == "true" ? (
                  <>
                    <li className="nav-item chatLink ">
                      <div
                        className="nav-link iconT"
                        style={{ cursor: "pointer" }}
                        title="CICO Beta"
                        onClick={() => this.BetaCICOHandler()}
                      >
                        {" "}
                        <img className="" src={betaIcon} />
                      </div>
                    </li>

                    <li className="nav-item chatLink ">
                      <div
                        className="nav-link iconT"
                        style={{ cursor: "pointer" }}
                        title="Ticket Support"
                        onClick={() => this.TicketHandler()}
                      >
                        {" "}
                        <img className="" src={tickeIcon} />
                      </div>
                    </li>

                    <li className="nav-item chatLink ">
                      <a
                        href="https://hr.mazaraat.org"
                        target="_blank"
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        title="HR / Murasalat"
                      >
                        {" "}
                        <img className="hrsysW" src={hrIcon} />
                      </a>
                    </li>
                  </>
                ) : null}
                {/* <li className="nav-item chatLink ">
            <a className="nav-link" style={{cursor:'pointer'}}>  <img src={chatIcon} /></a>
          </li> */}
                {localStorage.getItem("khidmatguzar") == "true" &&
                this.state.TileList.length > 0 ? (
                  <li className="nav-item reportLink dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      style={{ cursor: "pointer" }}
                      id="reportDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src={reportIcon} />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right  animated-growing"
                      aria-labelledby="reportDropdown"
                    >
                      {this.state.TileList.map((items) => (
                        <>
                          <NavLink
                            to={{
                              pathname: "/Temp",
                              state: {
                                reportID: items.reportID,
                                reportName: items.reportName,
                              },
                            }}
                            className="dropdown-item reportName"
                          >
                            {items.reportName}
                          </NavLink>
                          <div className="dropdown-divider" />
                        </>
                      ))}
                      {/* <NavLink to="/Reports" className="dropdown-item reportName">
                Faiz Report
                    </NavLink> */}
                      {/* <div className="dropdown-divider" /> */}
                      {/* <a className="dropdown-item" style={{cursor:'pointer'}}>
                Report 3
                    </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item" style={{cursor:'pointer'}}>
                Report 4
                    </a> */}
                    </div>
                  </li>
                ) : null}
                {localStorage.getItem("khidmatguzar") == "true" ||
                JSON.parse(localStorage.getItem("role")).findIndex(
                  (x) => x.roleName == "Laundry"
                ) != -1 ? (
                  <li className="nav-item reportLink dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      style={{ cursor: "pointer" }}
                      id="reportDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.ReceiptType == "a"
                        ? "A5 Print"
                        : "Thermal Print"}
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right  animated-growing"
                      aria-labelledby="reportDropdown"
                    >
                      <a
                        className="dropdown-item reportName"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.ChangeReceiptTypeHandler()}
                      >
                        {this.state.ReceiptType == "a"
                          ? "Thermal Print"
                          : "A5 Print"}
                      </a>
                    </div>
                  </li>
                ) : null}

                {/* <li className="nav-item chatLink ">
            <a className="nav-link" style={{cursor:'pointer'}}>  <img src={chatIcon} /></a>
          </li> */}
                {/*Notification dropdown*/}
                {/* <li className="nav-item dropdown notificationLink">
            <a className="nav-link " style={{cursor:'pointer'}} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src={bellicon} alt="notification" className="img-fluid" />
              <span className="badge badge-primary">1</span>
            </a>
            <ul className="dropdown-menu shadow animated-growing notificationDropdown">
              <li className="notificationHead text-light bg-dark">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-12">
                    <span>Notifications (1)</span>
                  </div>
                </div></li>
              <li className="notification-box">
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-3 text-center">
                    <img src={userimage} className="w-50 rounded-circle" />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-8">
                    <strong className="text-info">Husain Ezzy</strong>
                    <div>
                     Access Request pending for aprroval
                          </div>
                    <small className="text-warning">27.11.2015, 15:00</small>
                  </div>
                </div>
              </li>
              </ul>
          </li>
           */}
                {/* Nav Item - User Information */}
                {/* {localStorage.getItem("link") != "/Kiosk" ? */}
                <li className="nav-item dropdown ">
                  <a
                    className="nav-link dropdown-toggle"
                    style={{ cursor: "pointer" }}
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      className="img-profile rounded-circle"
                      src={userimage}
                    />
                    <div className="d-inline-flex flex-column align-middle usernameAndRole">
                      <span className="ml-2  d-block">
                        {localStorage.username}
                      </span>
                      <span className="ml-2  d-block small">
                        {localStorage.role2}
                      </span>
                    </div>
                  </a>
                  {/* Dropdown - User Information */}
                  <div
                    className="dropdown-menu dropdown-menu-right  animated-growing"
                    aria-labelledby="userDropdown"
                  >
                    <NavLink className="dropdown-item" to="/logout">
                      Logout
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <div
            className="modal fade searchModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Search</h5>
                  <div className="row">
                    <div class="searchBox">
                      {/* <input type="search" maxlength="100" class="form-control" placeholder="Search ITS, Name"/> */}
                      <input
                        title={this.state.Searchkey}
                        maxlength="100"
                        type="search"
                        className="form-control mr-1"
                        placeholder="Text"
                        onChange={(event) => this.SearchDataHandler(event)}
                      />
                    </div>
                    <button class="btn ml-1" onClick={() => this.SearchList()}>
                      Search
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="SearchList table-responsive-sm">
                    <table className="table table-fixed">
                      <thead>
                        <tr>
                          {/* <th scope="col"> No.</th> */}
                          <th scope="col">Booking ID</th>
                          <th scope="col">ITS No.</th>
                          <th scope="col">Name</th>
                          <th scope="col">Mobile No.</th>
                          <th scope="col">Email ID</th>
                          <th scope="col">Address</th>
                          <th scope="col">Room/Building</th>
                          <th scope="col">From</th>
                          <th scope="col">To</th>
                        </tr>
                      </thead>
                      <CustomScroll>
                        <tbody>
                          {this.state.SearchList.length > 0
                            ? this.state.SearchList.map((items) => (
                                <tr>
                                  {JSON.parse(localStorage.getItem("role"))[0]
                                    .roleName == "Manager" ||
                                  JSON.parse(
                                    localStorage.getItem("role")
                                  ).findIndex(
                                    (x) => x.roleName == "Reception"
                                  ) != -1 ? (
                                    items.bookingID == "-" ? (
                                      <td>One Time Niyaz</td>
                                    ) : (
                                      <td
                                        title={items.bookingID}
                                        className="bookingIDPopUp"
                                        data-toggle="modal"
                                        data-target=".popupModal"
                                        data-whatever=""
                                        onClick={(event) =>
                                          this.BPopUpHandler(
                                            items.bookingID,
                                            items.isActive == 1 ? true : false
                                          )
                                        }
                                      >
                                        {items.bookingID}
                                      </td>
                                    )
                                  ) : (
                                    <td title={items.bookingID}>
                                      {items.bookingID}
                                    </td>
                                  )}
                                  {/* <td data-toggle="modal" data-target=".popupModal" title={items.bookingID}>{items.bookingID}</td> */}
                                  <td title={items.itsid}>{items.itsid}</td>
                                  <td title={items.itsName}>{items.itsName}</td>
                                  <td title={items.contactNumber}>
                                    {items.contactNumber}
                                  </td>
                                  <td title={items.emailID}>{items.emailID}</td>
                                  <td title={items.address}>{items.address}</td>
                                  <td>
                                    {items.roomList.map((items) => (
                                      <div
                                        title={
                                          items.roomNumber +
                                          " (" +
                                          items.buildingName +
                                          ")"
                                        }
                                      >
                                        {items.roomNumber} ({items.buildingName}
                                        )
                                        <br />
                                      </div>
                                    ))}
                                  </td>
                                  <td title={items.checkIn}>{items.checkIn}</td>
                                  <td title={items.checkOut}>
                                    {items.checkOut}
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </CustomScroll>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade popupModal "
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.bdata != undefined ? (
                    <div className="container-fluid">
                      <div className="row mb-4">
                        <div className="col-8">
                          <h2 className="m-0">
                            Booking Details -{" "}
                            <u>
                              <b>{this.state.bdata.bookingID}</b>
                            </u>{" "}
                            &nbsp;
                            {this.state.bdata.bookingStatus == "Occupied" ? (
                              <span className="badge badge-success">
                                {this.state.bdata.bookingStatus}
                              </span>
                            ) : this.state.bdata.bookingStatus ==
                              "Checked-Out" ? (
                              <span className="badge badge-pending">
                                {this.state.bdata.bookingStatus}
                              </span>
                            ) : (
                              <span className="badge badge-warning">
                                {this.state.bdata.bookingStatus}
                              </span>
                            )}
                          </h2>
                        </div>
                        <div className="col-4 text-right">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <div className="personalInfo">
                            <div className="nameAndIts text-center">
                              <div className="img">
                                <img
                                  src={Userimg}
                                  alt="Generic placeholder image"
                                />
                              </div>
                              <p className="name ">
                                {this.state.bdata.itsName}
                              </p>
                              <p className="itsNo">{this.state.bdata.itsid}</p>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="p-1">
                                <h5>Check In</h5>
                                <p>{this.state.bdata.checkIn}</p>
                                {/* <p>09:12 am</p> */}
                              </div>

                              <div className="p-1 text-right">
                                <h5>Check Out</h5>
                                <p>{this.state.bdata.checkOut}</p>
                                {/* <p>10:30 am</p> */}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="p-1">
                                <h5>Phone No.</h5>
                                <p>
                                  {this.state.bdata.contactNumber == ""
                                    ? "-"
                                    : this.state.bdata.contactNumber}
                                </p>
                              </div>

                              <div className="p-1 text-right">
                                <h5>Email</h5>
                                <p>
                                  {this.state.bdata.emailID == ""
                                    ? "-"
                                    : this.state.bdata.emailID}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="p-1">
                                <h5>Address</h5>
                                <p>
                                  {this.state.bdata.address == ""
                                    ? "-"
                                    : this.state.bdata.address}
                                </p>
                              </div>

                              {/* <div className="p-1 text-right">
                                  <h5>Last Visit</h5>
                                  <p>12/03/16</p>
                                </div> */}
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="p-1">
                                <h5>Remarks</h5>
                                <p>
                                  {this.state.bdata.bookingRemarks == ""
                                    ? "-"
                                    : this.state.bdata.bookingRemarks}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="visibilityHide">
                            <ReactToPrint
                              trigger={() => (
                                <div id="clickButtonPrint1">Print this out</div>
                              )}
                              content={() => this.componentRef1}
                              onAfterPrint={() => window.location.reload(false)}
                            />
                          </div>
                          <div className="visibilityHide">
                            <ReactToPrint
                              trigger={() => (
                                <div id="clickButtonPrint2">Print this out</div>
                              )}
                              content={() => this.componentRef2}
                              onAfterPrint={() => window.location.reload(false)}
                            />
                          </div>
                        </div>
                        <div className="col-5 borderLR">
                          <div className="userServiceInfo">
                            <div className="form-row mt-4">
                              <div className="form-group col-md-5">
                                <h4>
                                  <u>Room Details</u>
                                </h4>
                              </div>
                              <div className="form-group col-md-7 text-right">
                                <div className="custom-control-inline ml-4 custom-checkbox ">
                                  {/* <label
                                    className="custom-control-label"
                                    htmlFor="selectmanually"
                                    >Select Manually</label>
                                     <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="selectmanually"
                                    //checked={this.state.selectenable}
                                    onChange={()=>this.SelectHandler()}
                                    /> */}

                                  <Aux1>
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="selectmanually"
                                      //checked={items.IsSelected}
                                      onChange={() => this.SelectHandler()}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="selectmanually"
                                    >
                                      Select Manually
                                    </label>
                                  </Aux1>
                                </div>
                              </div>
                            </div>

                            <table className="table borderless table-fixed roomDetaillist">
                              <thead>
                                <tr>
                                  {/* <th></th> */}
                                  <th>Room No</th>
                                  <th>Check-In</th>
                                  <th>Check-Out</th>
                                  <th>Hours / Days</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <CustomScroll>
                                <tbody>
                                  {this.state.bdata.roomList.map((items) => (
                                    <tr>
                                      {this.state.selectenable == true ? (
                                        <td>
                                          <div className="custom-control custom-checkbox">
                                            <Aux1>
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={items.roomID}
                                                //checked={items.IsSelected}
                                                onChange={() =>
                                                  this.AddCheckOutRoomHandler(
                                                    items
                                                  )
                                                }
                                              />
                                              <label
                                                className="custom-control-label mb-4"
                                                htmlFor={items.roomID}
                                              />
                                            </Aux1>
                                            {items.roomNumber} (
                                            {items.buildingName})
                                          </div>
                                        </td>
                                      ) : (
                                        <td>
                                          {items.roomNumber} (
                                          {items.buildingName})
                                        </td>
                                      )}
                                      <td>{items.checkIn}</td>
                                      <td>{items.checkOut}</td>
                                      <td>{items.hours}</td>
                                      <td>
                                        {items.roomStatus == "Occupied" ? (
                                          <span className="badge badge-success">
                                            {items.roomStatus}
                                          </span>
                                        ) : items.roomStatus ==
                                          "Building Check-Out Pending" ? (
                                          <span className="badge badge-warning">
                                            {items.roomStatus}
                                          </span>
                                        ) : (
                                          <span className="badge badge-pending">
                                            {items.roomStatus}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </CustomScroll>
                            </table>
                            <div className="form-row mt-4">
                              <div className="form-group col-md-5">
                                <h4>
                                  <u>Donation Details</u>
                                </h4>
                              </div>
                              <div className="form-group col-md-7 text-right">
                                Total Amount :{" "}
                                <b>
                                  {this.state.bdata.donationList.length > 0
                                    ? this.state.bdata.donationList[0]
                                        .donationTotal
                                    : 0}
                                </b>
                              </div>
                            </div>

                            <table className="table borderless table-fixed donationDetaillist">
                              <CustomScroll>
                                <tbody>
                                  {this.state.bdata.donationList.map(
                                    (items) => (
                                      <tr>
                                        <td>
                                          <b>{items.accountForName}</b>
                                        </td>
                                        <td>
                                          <b>
                                            {items.amount == 0
                                              ? items.comments
                                              : items.amount}
                                          </b>
                                        </td>
                                        <td />
                                        <td />
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </CustomScroll>
                            </table>
                          </div>
                        </div>
                        <div className="col-5">
                          {/* <div className="otherVisitorDetails"> */}
                          <div className="userServiceInfo">
                            <h4>
                              <u>Other Visitor Details</u>
                            </h4>
                            <div className="form-row mt-4">
                              <div className="form-group col-md-9">
                                {this.state.bdata.visitorList.length > 0
                                  ? "Male:" +
                                    this.state.bdata.visitorList[0].male +
                                    " , Female:" +
                                    this.state.bdata.visitorList[0].female +
                                    " ,Child:" +
                                    this.state.bdata.visitorList[0].child
                                  : "Male:0" + " ,Female:0" + " ,Child:0"}
                              </div>
                              <div className="form-group col-md-3 text-right">
                                Total Visitor :{" "}
                                {this.state.bdata.visitorList.length > 0
                                  ? this.state.bdata.visitorList[0].total
                                  : 0}
                              </div>
                            </div>
                            <div>
                              <table className="table borderless table-fixed visitorList">
                                <thead>
                                  <tr>
                                    {/* <th></th> */}
                                    <th>ITS Number</th>
                                    <th>Visitor Name</th>
                                    <th>Age</th>
                                    {/* <th>Gender</th> */}
                                    {/* <th>Address</th> */}
                                    <th>Check-In</th>
                                    <th>Check-Out</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <CustomScroll>
                                  <tbody>
                                    {this.state.bdata.visitorList.map(
                                      (items) => (
                                        <tr>
                                          {this.state.selectenable == true ? (
                                            <td className="">
                                              <div className="custom-control custom-checkbox">
                                                <Aux1>
                                                  <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={items.itsid}
                                                    //checked={items.IsSelected}
                                                    onChange={() =>
                                                      this.AddCheckOutZuwarHandler(
                                                        items
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={items.itsid}
                                                  />
                                                </Aux1>
                                                <img
                                                  src={Userimg}
                                                  alt="Generic placeholder image"
                                                />
                                                <br /> {items.itsid}{" "}
                                                {items.warning != "" ? (
                                                  <Tippy
                                                    placement="right-start"
                                                    content={items.warning}
                                                  >
                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="exclamBtn"
                                                    />
                                                  </Tippy>
                                                ) : null}
                                              </div>
                                            </td>
                                          ) : (
                                            <td className="text-center">
                                              <img
                                                src={Userimg}
                                                alt="Generic placeholder image"
                                              />
                                              <br /> {items.itsid}{" "}
                                              {items.warning != "" ? (
                                                <Tippy
                                                  placement="right-start"
                                                  content={items.warning}
                                                >
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="exclamBtn"
                                                  />
                                                </Tippy>
                                              ) : null}
                                            </td>
                                          )}
                                          <td>{items.itsName}</td>
                                          <td>
                                            {items.age} ({items.gender})
                                          </td>
                                          {/* <td>Male</td> */}
                                          {/* <td>Pune|kasdkajs  kjashd akashdkas hkasdk askjhaskdjhakshdkj ashk jhd </td> */}
                                          <td>{items.checkIn}</td>
                                          <td>{items.checkOut}</td>
                                          {/* <td>Building CheckOut Pending</td> */}
                                          <td>
                                            {items.status == "Checked-In" ? (
                                              <span className="badge badge-success">
                                                {items.status}
                                              </span>
                                            ) : items.status ==
                                              "Building Check-Out Pending" ? (
                                              <span className="badge badge-warning">
                                                {items.status}
                                              </span>
                                            ) : (
                                              <span className="badge badge-pending">
                                                {items.status}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </CustomScroll>
                              </table>
                            </div>
                          </div>
                        </div>
                        {this.state.bdata.checkOutFlag == 1 ? (
                          <div className="col-8 text-right mt-4">
                            <button
                              className="btn mr-2"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                this.ReceiptHandler(
                                  this.state.bdata.bookingID,
                                  true
                                )
                              }
                            >
                              Re-Print Check-In Receipt
                            </button>
                            <button
                              className="btn"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                this.ReceiptHandler(
                                  this.state.bdata.bookingID,
                                  false
                                )
                              }
                            >
                              Re-Print Check-Out Receipt
                            </button>
                            {/* <button className="btn text-right" data-dismiss="modal" aria-label="Close">Re-Print Check-Out Receipt</button> */}
                          </div>
                        ) : (
                          <div className="col-12 text-center mt-4">
                            <button
                              className="btn"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                this.ReceiptHandler(
                                  this.state.bdata.bookingID,
                                  false
                                )
                              }
                            >
                              Re-Print Check-Out Receipt
                            </button>
                          </div>
                        )}
                        {this.state.bdata.checkOutFlag == 1 &&
                        (this.props.showcheckout == undefined ||
                          this.props.showcheckout == true) ? (
                          <div className="col-4 text-right mt-4">
                            {/* <button className="btn text-right" data-dismiss="modal" aria-label="Close" onClick={(event)=>this.CheckOutHandler(event,this.state.bdata.bookingID)}>Check-Out</button> */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <ComponentToPrint3
              data={this.state.ReceiptData}
              ref={(el) => (this.componentRef1 = el)}
            />
          </div>
          <div style={{ display: "none" }}>
            <ComponentToPrint2
              data={this.state.ReceiptData}
              ref={(el) => (this.componentRef2 = el)}
            />
          </div>
          <a
            id="link"
            href={this.state.url}
            target="_blank"
            rel="noopener noreferrer"
            className="visibilityHide"
          >
            a
          </a>
        </header>
        {/* {this.state.SearchList.length > 0 ? <PopUp bdata = {this.state.bdata} showcheckout={this.state.showcheckout}/>:null} */}
      </>
    );
  }
}
export default topheader;

//import Logo from '../../Logo/Logo';
//import NavigationItems from '../NavigationItems/NavigationItems';
//import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

// const toolbar = ( props ) => (
//     <header className={classes.Toolbar}>
//         <DrawerToggle clicked={props.drawerToggleClicked} />
//         <div className={classes.Logo}>
//             <Logo />
//         </div>
//         <nav className={classes.DesktopOnly}>
//             <NavigationItems />
//         </nav>
//     </header>
// );

// export default toolbar;
