import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { NavLink } from "react-router-dom";
import SwitchComponent from "../../../App";
import classes from "./LeftNavigation.css";
import Aux1 from "../../../hoc/Aux1/Aux1";
import NavigationItems from "../NavigationItems/NavigationItems";

const leftNavigation = (props) => {
  return (
    <Aux1>
      <aside className="sidenav">
        <div className="crown topCrown" />
        <CustomScroll>
          <div className="navContent">
            <ul className="list-unstyled">
              <NavigationItems isAuthenticated={props.isAuth} />
            </ul>
          </div>
        </CustomScroll>
        <div className="crown bottomCrown" />
      </aside>

      {SwitchComponent}
    </Aux1>
  );
};

export default leftNavigation;
