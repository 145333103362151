import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
//import burgerBuilderReducer from './store/reducers/burgerBuilder';
import orderReducer from './store/reducers/order';
import authReducer from './store/reducers/auth';
import navigationReducer from './store/reducers/navigation';
import { toast,Slide, Zoom, Flip, Bounce  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import packageJson from '../package.json';
// global.appVersion = packageJson.version;
//const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || composeWithDevTools;

const rootReducer = combineReducers({
    //burgerBuilder: burgerBuilderReducer,
    order: orderReducer,
    Navigation:navigationReducer,
    auth: authReducer
});

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <HashRouter>
        {toast.configure({
                    // className:"toasterContainer",
                    position:"top-right",
                    autoClose:2500,
                    hideProgressBar:true,
                    newestOnTop:true,
                    transition:Zoom,
                    //closeOnClick,
                    rtl:false,
                    //transition: "Rotate"
                    //pauseOnVisibilityChange,
                    //draggable,
                    //pauseOnHover
                    })}
            <App />
        </HashRouter>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
registerServiceWorker();
