import React from 'react';
import packageJson from '../package.json';
global.appVersion = packageJson.version;


class CacheBuster extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      isLatestVersion: false
    };
  }

  render() {
    const { loading, isLatestVersion} = this.state;
    return this.props.children({ loading, isLatestVersion});
  }
}

export default CacheBuster;