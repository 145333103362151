//import axios from 'axios';
import axios from '../../axios-api';

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('role2');
    localStorage.removeItem('username');
    localStorage.removeItem('khidmatguzar');
    localStorage.removeItem('link');
    localStorage.removeItem('temptoken');
    localStorage.removeItem('tempuser');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (email, password, isKhidmatGuzar) => {
    return dispatch => {
        let authData = {};
        dispatch(authStart());
        // if(isKhidmatGuzar)
        // {
            /// keep this on build
            //const authData = 
            authData=
                {
                UserName: email,
                Password: password,
                Id: localStorage.temptoken !== "" && localStorage.temptoken !== undefined ? 10 : isKhidmatGuzar == true?1:0,
                returnSecureToken: true,
                };
        //}
        // else{
        //  authData = {
           
        //      //comment on build
        //     email: email,
        //     password: password,
        //     returnSecureToken: true
        //      //comment on build end
        // };
        //}http://smms/API
        //let url = 'https://localhost:44338/Users/ValidateUser';
        //let url = 'http://smms/API/Users/ValidateUser';
        // if (!isKhidmatGuzar) {
        //     url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCUm7-vAf0qpL1XNunfFOrIZbozKNk9feA';
        // }
        const config = {
            headers: { Authorization: `Bearer ${localStorage.temptoken}` },
          };

      debugger;
       localStorage.temptoken !== "" && localStorage.temptoken !== undefined ? 
       axios.post("SSO/ValidateUser", authData, config)
            .then(response => {
                //  if(isKhidmatGuzar)
                //  {
                      /// keep this on build
                      debugger;
                      let role="";
                      let roleList =response.data.user.rList;
                      for (var i=0; i < roleList.length; i++)
                        {
                            if((i==0 && roleList.length == 1) || roleList.length -1 == i)
                            {
                                role = role + roleList[i].roleName
                            }
                            else if(roleList.length > 1 && roleList.length -1 != i)
                            {
                                role = role + roleList[i].roleName + ', '
                            }
                            // else if (roleList.length -1 == i)
                            // {

                            // }
                        }
                const expirationDate = new Date(new Date().getTime() + response.data.user.expiresin * 1000);
                localStorage.setItem('token', response.data.user.token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.user.itsid);
                localStorage.setItem('username', response.data.user.name);
                localStorage.setItem('role', JSON.stringify(response.data.user.rList));
                localStorage.setItem('role2', role);
                localStorage.setItem('khidmatguzar', isKhidmatGuzar);
                localStorage.setItem('link', response.data.user.url);
                localStorage.setItem('receipttype', localStorage.getItem("receipttype") != null ?localStorage.getItem("receipttype"):"a");
                localStorage.setItem('Kiosk', response.data.user.kiosk);
                localStorage.removeItem('temptoken');
                localStorage.removeItem('tempuser');
                dispatch(authSuccess(response.data.user.token, response.data.user.itsid));
                dispatch(checkAuthTimeout(response.data.user.expiresin));
                
            })
            .catch(err => {
                debugger;
                localStorage.removeItem('temptoken');
                localStorage.removeItem('tempuser');
                dispatch(authFail(err.response==undefined ?"Server Is Offline":"Invalid Username or Password"));
            })
       :
        axios.post("Users/ValidateUser", authData)
            .then(response => {
                //  if(isKhidmatGuzar)
                //  {
                      /// keep this on build
                      debugger;
                      let role="";
                      let roleList =response.data.user.rList;
                      for (var i=0; i < roleList.length; i++)
                        {
                            if((i==0 && roleList.length == 1) || roleList.length -1 == i)
                            {
                                role = role + roleList[i].roleName
                            }
                            else if(roleList.length > 1 && roleList.length -1 != i)
                            {
                                role = role + roleList[i].roleName + ', '
                            }
                            // else if (roleList.length -1 == i)
                            // {

                            // }
                        }
                const expirationDate = new Date(new Date().getTime() + response.data.user.expiresin * 1000);
                localStorage.setItem('token', response.data.user.token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.user.itsid);
                localStorage.setItem('username', response.data.user.name);
                localStorage.setItem('role', JSON.stringify(response.data.user.rList));
                localStorage.setItem('role2', role);
                localStorage.setItem('khidmatguzar', isKhidmatGuzar);
                localStorage.setItem('link', response.data.user.url);
                localStorage.setItem('receipttype', localStorage.getItem("receipttype") != null ?localStorage.getItem("receipttype"):"a");
                localStorage.setItem('Kiosk', response.data.user.kiosk);
                localStorage.removeItem('temptoken');
                localStorage.removeItem('tempuser');
                dispatch(authSuccess(response.data.user.token, response.data.user.itsid));
                dispatch(checkAuthTimeout(response.data.user.expiresin));
                //  }
                //  else{ 
                //  //comment on build
                // const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                // localStorage.setItem('token', response.data.idToken);
                // localStorage.setItem('expirationDate', expirationDate);
                // localStorage.setItem('userId', response.data.localId);
                // dispatch(authSuccess(response.data.idToken, response.data.localId));
                // dispatch(checkAuthTimeout(response.data.expiresIn));
                //  //comment on build end
                //  }
            })
            .catch(err => {
                debugger;
                localStorage.removeItem('temptoken');
                localStorage.removeItem('tempuser');
                dispatch(authFail(err.response==undefined ?"Server Is Offline":"Invalid Username or Password"));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }   
        }
    };
};