
import React, { Component } from 'react';
import CustomScroll from 'react-custom-scroll';
import Tippy, { tippy } from '@tippy.js/react';
import axios from '../../axios-api';
import { toast } from 'react-toastify';
import 'tippy.js/dist/tippy.css';
import './footer.css';
let Userimg = require('../../styles/img/user.png');

class Footer extends Component {
  render() {
    return (

      <footer>
        <nav className="navbar fixed-bottom navbar-light ">
          <div className="pl-4">Copyright © 2021 Sigatul Mazaraat - All rights reserved</div>
          <div>Version - {global.appVersion}</div>
          <div className="">Designed &amp; Developed by <a href="https://babjisoftech.com/"  target="_blank" className="text-underline">Babji Softech</a></div>
        </nav>
        
      </footer>

    );
  }
}

export default Footer;


