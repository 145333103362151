import React from 'react';
import { NavLink } from 'react-router-dom';

const navigationItem = ( props ) => (
    <li className={(props.children.toLowerCase()).replace(/\s/g,'')}>
        <NavLink 
            to={props.link}
            exact={props.exact}>{props.children}</NavLink>
            {/* <NavLink className="nav-link" to="/products">Products</NavLink> */}
    </li>
);

export default navigationItem;
